import TwoCirclesSpinner, { TwoCirclesSpinnerProps } from 'components/Spinner/TwoCirclesSpinner'
import styles from './loadingContent.module.css'

type Props = {
  label?: string,
  spinner?: ({ scale, label, color }: TwoCirclesSpinnerProps) => JSX.Element,
  minHeight?: number,
  scale?: number,
}

const LoadingContent = ({
  label = 'Loading...',
  spinner: SpinnerComponent = TwoCirclesSpinner,
  minHeight = 400,
  scale = 1,
}: Props) => {
  return <div className={styles.loadingContent} style={{ minHeight }}>
    <SpinnerComponent label={label} scale={scale} />
  </div>
}

export default LoadingContent
