import { Dialog, Transition } from '@headlessui/react'
import {
  XIcon,
  // ClockIcon,
  // CogIcon,
  // CreditCardIcon,
  // DocumentReportIcon,
  HomeIcon,
  QuestionMarkCircleIcon,
  // ScaleIcon,
  ShieldCheckIcon,
  // UserGroupIcon,
  ChatIcon,
  DocumentTextIcon,
  // ChatAltIcon,
  // ChartBarIcon,
  LogoutIcon,
} from '@heroicons/react/outline'
import { Dispatch, Fragment, SetStateAction, useEffect, useState } from 'react'
import BlotterLogo from 'components/BlotterLogo'
import OnboardingChecklist from 'components/OnboardingChecklist'
import styles from './Sidebar.module.css'
import { signOut } from 'next-auth/react'
import { isServer } from 'utilities/window'
import { isUndefined } from 'lodash'
import { useCurrentBlogId } from 'data/blogs/client'

type NavigationItem = {
  name: string,
  appPath?: string,
  sitePath?: string,
  icon: React.ReactNode,
}

const navigation: NavigationItem[] = [
  { name: 'Home', appPath: '', icon: HomeIcon },
  { name: 'Blog Posts', appPath: '/blogposts', icon: DocumentTextIcon },
  { name: 'Comments', appPath: '/comments', icon: ChatIcon },
  // { name: 'Tweets', href: '#', icon: ChatAltIcon },
  // { name: 'Analytics', href: '#', icon: ChartBarIcon },
  // { name: 'Reports', href: '#', icon: DocumentReportIcon },
]
const secondaryNavigation = [
  // { name: 'Settings', appPath: 'settings', icon: CogIcon },
  { name: 'Help', appPath: '/support', icon: QuestionMarkCircleIcon },
  { name: 'Privacy', sitePath: '/privacy', icon: ShieldCheckIcon },
]

const getNavigationPath = (item: NavigationItem, hostId: string) => {
  // console.log('getNavPath', hostId, item.appPath, item.sitePath)
  if (!isUndefined(item.appPath)) {
    return `/app/host/${hostId}${item.appPath}`
  }
  if (!isUndefined(item.sitePath)) {
    return `${item.sitePath}`
  }
  return '/'
}

const getIsCurrentPage = (item: NavigationItem, hostId: string) => {
  if (isServer()) {
    return false
  }
  const itemPath = getNavigationPath(item, hostId)
  // console.log(window?.location?.pathname, '===', itemPath)
  return window?.location?.pathname === itemPath
}

type Props = {
  isSidebarOpen?: boolean,
  setIsSidebarOpen?: Dispatch<SetStateAction<boolean>> | ((isOpen?: boolean) => any),
}

const Sidebar = ({ isSidebarOpen = false, setIsSidebarOpen = () => {} }: Props) => {
  // annoying work-around for React hydration error
  // set empty value with useState, then after hydration set correct value via useEffect
  const [hostId, setHostId] = useState('')
  const clientSideHostId = useCurrentBlogId() || ''
  useEffect(() => {
    setHostId(clientSideHostId)
  }, [clientSideHostId])
  return <>
    <Transition.Root show={isSidebarOpen} as={Fragment}>
      <Dialog as="div" className={styles.dialog} onClose={setIsSidebarOpen}>
        <Transition.Child
          as={Fragment}
          enter={styles.overlayEnter}
          enterFrom={styles.overlayEnterFrom}
          enterTo={styles.overlayEnterTo}
          leave={styles.overlayLeave}
          leaveFrom={styles.overlayLeaveFrom}
          leaveTo={styles.overlayLeaveTo}
        >
          <Dialog.Overlay className={styles.overlay} />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter={styles.sidebarEnter}
          enterFrom={styles.sidebarEnterFrom}
          enterTo={styles.sidebarEnterTo}
          leave={styles.sidebarLeave}
          leaveFrom={styles.sidebarLeaveFrom}
          leaveTo={styles.sidebarLeaveTo}
        >
          <div className={styles.closeButtonTransition}>
            <Transition.Child
              as={Fragment}
              enter={styles.closeButtonEnter}
              enterFrom={styles.closeButtonEnterFrom}
              enterTo={styles.closeButtonEnterTo}
              leave={styles.closeButtonLeave}
              leaveFrom={styles.closeButtonLeaveFrom}
              leaveTo={styles.closeButtonLeaveTo}
            >
              <div className={styles.closeButtonContainer}>
                <button
                  type="button"
                  className={styles.closeButton}
                  onClick={() => setIsSidebarOpen(false)}
                >
                  <span className={styles.screenReaderOnly}>Close sidebar</span>
                  <XIcon className={styles.closeIcon} aria-hidden="true" />
                </button>
              </div>
            </Transition.Child>
            <div className={styles.logoContainer}>
              <BlotterLogo />
            </div>
            <nav
              className={styles.navigationContainerMobile}
              aria-label="Sidebar"
            >
              <div className={styles.navigation}>
                {hostId && navigation.map((item) => (
                  <a
                    key={item.name}
                    href={getNavigationPath(item, hostId)}
                    className={
                      getIsCurrentPage(item, hostId)
                        ? styles.navigationItemActive
                        : styles.navigationItemInactive
                    }
                    aria-current={getIsCurrentPage(item, hostId) ? 'page' : undefined}
                  >
                    <item.icon className={styles.navigationItemIcon} aria-hidden="true" />
                    {item.name}
                  </a>
                ))}
                <button
                  onClick={() => { signOut() }}
                  className={styles.navigationButton}
                >
                  <LogoutIcon className={styles.navigationItemIcon} aria-hidden='true' />
                  <div>Logout</div>
                </button>
              </div>
              <div className={styles.navigationSecondaryContainer}>
                <div className={styles.navigationSecondary}>
                  {secondaryNavigation.map((item) => (
                    <a
                      key={item.name}
                      href={getNavigationPath(item, hostId)}
                      className={styles.navigationSecondaryItem}
                    >
                      <item.icon className={styles.navigationSecondaryIcon} aria-hidden="true" />
                      {item.name}
                    </a>
                  ))}
                </div>
              </div>
            </nav>
          </div>
        </Transition.Child>
        <div className={styles.dummyItem} aria-hidden="true">
          {/* Dummy element to force sidebar to shrink to fit close icon */}
        </div>
      </Dialog>
    </Transition.Root>

    {/* Static sidebar for desktop */}
    <div className={styles.sidebarContainerDesktop}>
      {/* Sidebar component, swap this element with another sidebar if you like */}
      <div className={styles.sidebarDesktop}>
        <div className={styles.logoContainer}>
          <BlotterLogo />
        </div>
        <nav className={styles.navigationContainer} aria-label="Sidebar">
          <div className={styles.onboardingChecklistContainer}>
            <OnboardingChecklist isModalEnabled={true} />
          </div>
          <div className={styles.navigation} suppressHydrationWarning={true}>
            {navigation.map((item) => (
              <a
                key={item.name}
                href={getNavigationPath(item, hostId)}
                className={
                  getIsCurrentPage(item, hostId)
                    ? styles.navigationItemActive
                    : styles.navigationItemInactive
                }
                aria-current={getIsCurrentPage(item, hostId) ? 'page' : undefined}
              >
                <item.icon className={styles.navigationItemIcon} aria-hidden="true" />
                {item.name}
              </a>
            ))}
          </div>
          <div className={styles.navigationSecondaryContainer}>
            <div className={styles.navigationSecondary} suppressHydrationWarning={true}>
              {secondaryNavigation.map((item) => (
                <a
                  key={item.name}
                  href={getNavigationPath(item, hostId)}
                  className={styles.navigationSecondaryItem}
                >
                  <item.icon className={styles.navigationItemIcon} aria-hidden="true" />
                  {item.name}
                </a>
              ))}
            </div>
          </div>
        </nav>
      </div>
    </div>
  </>
}

export default Sidebar
