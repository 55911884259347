import Image from 'next/image'
import styles from './LoginView.module.css'
import ottersBackground from './otters_background.jpg'
// import PageWithTailwind from 'components/PageWithTailwind'
import BlotterLogo from 'components/BlotterLogo'
// import AccountWidget from 'components/AccountWidget'
import Link from 'next/link'
import TwitterLogin from 'components/TwitterLogin'
// import { useRouter } from 'next/router'

const LoginView = () => {
  // const router = useRouter()
  return <div className={styles.loginView}>
    <div className={styles.loginPanel}>
      <div className={styles.loginForm}>
        <div>
          <Link href='/'>
            <a className={styles.logo}>
              <BlotterLogo />
            </a>
          </Link>
          {/* <img
            className="h-12 w-auto"
            src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
            alt="Workflow"
          /> */}
          {/* <h2 className={styles.signInTitle}>Sign in using Twitter</h2> */}
          {/* <p className="mt-2 text-sm text-gray-600">
            Or{' '}
            <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
              start your 14-day free trial
            </a>
          </p> */}
        </div>
        {/* <AccountWidget /> */}
        <div className="mt-8">
          <TwitterLogin />
        </div>
      </div>
    </div>
    <div className="block relative flex-1">
      <Image
        src={ottersBackground}
        layout='fill'
        objectFit='cover'
        priority
      />
    </div>
  </div>
}


export default LoginView
