// import styles from './RadioGroup.module.css'
import { useEffect, useState } from 'react'
import { RadioGroup as HuiRadioGroup } from '@headlessui/react'
import { isFunction } from 'lodash'
import classNames from 'classnames'

export type Option = {
  value: string,
  title: string | React.ReactNode,
  body: string | React.ReactNode,
}

type Props = {
  options: Option[],
  titleForScreenReader: string,
  value?: Option,
  onChange?: (selectedValue: Option) => void,
  suppressClosingStyling?: boolean,
}

const RadioGroup = ({
  options,
  onChange,
  titleForScreenReader,
  value,
  suppressClosingStyling = false
}: Props) => {
  const [selected, setSelected] = useState<Option>()
  useEffect(() => {
    const newSelection = options.find(option => option.value === value?.value)
    setSelected(newSelection)
  }, [value])

  const onRadioGroupChange = (item: Option) => {
    console.log('RADIO GROUP CHANGE', item)
    isFunction(onChange) && onChange(item)
    setSelected(item)
  }
  // console.log('RENDER', { selected })
  return (
    <HuiRadioGroup value={selected} onChange={onRadioGroupChange}>
      <HuiRadioGroup.Label className="sr-only">{ titleForScreenReader }</HuiRadioGroup.Label>
      <div className={ classNames('bg-white -space-y-px', suppressClosingStyling ? 'rounded-t-md' :
      'rounded-md')}>
        {options.map((setting, settingIdx) => (
          <HuiRadioGroup.Option
            key={setting.value}
            // tabIndex={0}
            value={setting}
            className={() => // { checked }
              classNames(
                settingIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                settingIdx === options.length - 1
                  ? suppressClosingStyling
                    ? 'border-b-0'
                    : 'rounded-bl-md rounded-br-md'
                  : '',
                setting.value === selected?.value ? 'bg-indigo-50 border-indigo-200 z-10' : 'border-gray-200',
                'relative border p-4 flex cursor-pointer focus:outline-none'
              )
            }
          >
            {({ active }) => (  // checked param is not working
              <>
                <span
                  className={classNames(
                    setting.value === selected?.value ? 'bg-indigo-600 border-transparent' : 'bg-white border-gray-300',
                    active ? 'ring-2 ring-offset-2 ring-indigo-500' : '',
                    'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center'
                  )}
                  aria-hidden="true"
                >
                  <span className="rounded-full bg-white w-1.5 h-1.5" />
                </span>
                <div className="ml-3 flex flex-col" title={setting.title}>
                  <HuiRadioGroup.Label
                    as="span"
                    className={classNames(setting.value === selected?.value ? 'text-indigo-900' : 'text-gray-900', 'block text-sm font-medium max-w-[30ch] sm:max-w-[40ch] overflow-hidden text-ellipsis')}
                  >
                    {setting.title}
                  </HuiRadioGroup.Label>
                  <HuiRadioGroup.Description
                    as="span"
                    className={classNames(setting.value === selected?.value ? 'text-indigo-700' : 'text-gray-500', 'block text-sm')}
                  >
                    {setting.body}
                  </HuiRadioGroup.Description>
                </div>
              </>
            )}
          </HuiRadioGroup.Option>
        ))}
      </div>
    </HuiRadioGroup>
  )
}

export default RadioGroup
