import { EmailVerificationStatus } from '@prisma/client'
import Button from 'components/Button'
import { updateUser, useUser } from 'data/users/client'
import { FormEvent, useEffect, useState } from 'react'
import { isEmailAddress } from 'utilities/text'
import styles from './EmailAddressSelector.module.css'

const EmailAddressSelector = () => {
  const { user, refreshUser } = useUser()
  const [emailAddress, setEmailAddress] = useState(user?.email || '')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const isVerifying = user?.emailVerificationStatus === EmailVerificationStatus.VERIFYING

  useEffect(() => {
    if (user?.email) {
      setEmailAddress(user.email)
    }
  }, [user])

  const handleFormSubmit = async (e?: FormEvent<HTMLFormElement> | MouseEvent) => {
    e?.preventDefault()
    if (isSubmitting) return
    setIsSubmitting(true)
    await updateUser(emailAddress)
    setIsSubmitting(false)
    refreshUser()
  }
  return <div className={styles.emailAddressSelector}>

    <form onSubmit={handleFormSubmit}>
      <div>
        <div className='flex justify-between'>
          <label htmlFor='userEmail' className='block text-sm font-medium text-gray-700'>
            Email address
          </label>
          <span className='text-sm text-gray-500' id='userEmail-optional'>
            Optional
          </span>
        </div>
        { isVerifying
          ? <>Confirmation email sent to <em className='break-words'>{user?.email}</em>! Please click the link in your inbox to confirm your email address.</>
          : <>
            <p className='text-xs text-gray-500'>Your email address is not required, but recommended. <strong>We never spam.</strong> Blotter uses email for service-related messages and backup account access.</p>
            <div className='mt-1'>
              <input
                type='email'
                inputMode='email'
                name='userEmail'
                id='userEmail'
                className='shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md'
                placeholder='myemail@example.com'
                aria-describedby='userEmail-optional'
                value={emailAddress}
                onChange={(ev) => { setEmailAddress(ev?.target?.value) }}
              />
            </div>
          </>
        }
      </div>
      { !isVerifying && <div className='mt-4 w-full'>
        <Button
          variant='medium'
          isDisabled={!isEmailAddress(emailAddress) || isSubmitting}
          isWorking={isSubmitting}
          isWorkingLabel='Saving...'
          type='submit'
          // onClick={() => { handleFormSubmit() }}
        >Save Email Address &raquo;</Button>
      </div> }
    </form>

  </div>
}

export default EmailAddressSelector
