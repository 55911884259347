export const runClientSide = (clientSideFn:Function): Function | boolean => {
  if (typeof window !== 'undefined') {
    return clientSideFn()
  }
  return false
}

export const isServer = () => {
  return typeof window === 'undefined'
}

export const isBrowser = () => {
  return !isServer()
}
