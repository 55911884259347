import styles from './OnboardingChecklist.module.css'
import { CheckIcon, UserIcon } from '@heroicons/react/solid'
import { AtSymbolIcon, CalendarIcon, ChartBarIcon, ChevronDoubleRightIcon, ChevronDownIcon, ChevronRightIcon, CodeIcon } from '@heroicons/react/outline'
import BlotterLogo from 'components/BlotterLogo'
import { useUser } from 'data/users/client'
import { first, isEmpty } from 'lodash'
import DomainSelector from 'components/DomainSelector'
// import { UserWithBlogs } from 'data/users/types'
import { SVGProps, useEffect } from 'react'
import classNames from 'classnames'
import { useBlogs } from 'data/blogs/client'
import { Blog, EmailVerificationStatus, User } from '@prisma/client'
import { usePagesForDomain } from 'data/pages/client'
import { Page } from 'data/pages/types'
import EmailAddressSelector from 'components/EmailAddressSelector'

type StatusCheckPayload = {
  user?: User,
  blogs?: Blog[],
  pages?: Page[],
}

enum Status {
  done = 'DONE',
  todo = 'TODO',
  blocked = 'BLOCKED',
}

type OnboardingEvent = {
  id: number,
  contentTodo: string,
  contentDone: string,
  isComplete: (data: StatusCheckPayload) => boolean,
  isBlocked: (data: StatusCheckPayload) => boolean,
  renderActiveBody: () => React.ReactNode | void,
  // target: '',
  href: string,
  date: string,
  datetime?: string,
  target?: string,
  icon: (props: SVGProps<SVGSVGElement>) => JSX.Element,
  secondaryIconTodo: React.ReactNode,
  secondaryIconDone: React.ReactNode,
  status?: Status,
}

const onboardingEvents: OnboardingEvent[] = [
  {
    id: 1,
    contentTodo: 'Create account',
    contentDone: 'Account created',
    isComplete: () => true,
    isBlocked: () => false,
    renderActiveBody: () => undefined,
    // target: '',
    href: '#',
    date: 'Sep 20',
    datetime: '2020-09-20',
    icon: UserIcon,
    secondaryIconTodo: <CheckIcon className='w-4 h-4 text-green-400' />,
    secondaryIconDone: <CheckIcon className='w-4 h-4 text-green-400' />,
  },
  {
    id: 2,
    contentTodo: 'Verify Your Email Address',
    contentDone: 'Email verified',
    isComplete: (data) => data.user?.emailVerificationStatus === EmailVerificationStatus.VERIFIED,
    isBlocked: () => false,
    renderActiveBody: () => <EmailAddressSelector />,
    // target: '',
    href: '#',
    date: 'Sep 20',
    datetime: '2020-09-20',
    icon: AtSymbolIcon,
    secondaryIconTodo: <CheckIcon className='w-4 h-4 text-green-400' />,
    secondaryIconDone: <CheckIcon className='w-4 h-4 text-green-400' />,
  },
  {
    id: 3,
    contentTodo: 'Enter blog details',
    contentDone: 'Blog details entered',
    isComplete: (data) => (data.blogs?.length || 0) > 0,
    isBlocked: () => false,
    renderActiveBody: () => <DomainSelector />,
    // target: 'blog domain',
    href: '#',
    date: 'Sep 22',
    datetime: '2020-09-22',
    icon: ChevronDoubleRightIcon,
    secondaryIconTodo: <ChevronDownIcon className='w-4 h-4 text-cerise-400' />,
    secondaryIconDone: <CheckIcon className='w-4 h-4 text-green-400' />,
  },
  {
    id: 4,
    contentTodo: 'Install code snippet',
    contentDone: 'Code snippet installed',
    isBlocked: (data) => (data.blogs?.length || 0) === 0,
    isComplete: (data) => {
      // console.log('IS COMPLETE????', { data })
      return !!data.pages?.find(page => page.isPageViewConfirmed)
      // const blogWithViews = data.blogs.find((blog) => { blog.views
      // data.blogs.length > 0
    },
    renderActiveBody: () => undefined,
    // target: '',
    href: '#',
    date: 'Sep 28',
    datetime: '2020-09-28',
    icon: CodeIcon,
    secondaryIconTodo: <ChevronRightIcon className='w-4 h-4 text-cerise-400' />,
    secondaryIconDone: <CheckIcon className='w-4 h-4 text-green-400' />,
  },
  // {
  //   id: 5,
  //   contentTodo: 'Process analytics',
  //   contentDone: 'Analytics ready',
  //   isComplete: () => false,
  //   isBlocked: (data) => !data.pages?.find(page => page.isPageViewConfirmed),
  //   renderActiveBody: () => undefined,
  //   // target: '',
  //   href: '#',
  //   date: 'Sep 30',
  //   datetime: '2020-09-30',
  //   icon: ChartBarIcon,
  //   secondaryIconTodo: <ChevronRightIcon className='w-4 h-4 text-cerise-400' />,
  //   secondaryIconDone: <CheckIcon className='w-4 h-4 text-green-400' />,
  // },
  {
    id: 6,
    contentTodo: 'Share first post',
    contentDone: 'First post scheduled',
    isComplete: () => false,
    isBlocked: () => true,
    renderActiveBody: () => undefined,
    target: '',
    href: '#',
    date: 'Oct 4',
    datetime: '2020-10-04',
    icon: CalendarIcon,
    secondaryIconTodo: <ChevronRightIcon className='w-4 h-4 text-cerise-400' />,
    secondaryIconDone: <CheckIcon className='w-4 h-4 text-green-400' />,
  },
]

// function classNames(...classes) {
//   return classes.filter(Boolean).join(' ')
// }

const getIconBackground = (status?: Status) => {
  if (status === Status.done) {
    return 'bg-green-400'
  }
  if (status === Status.todo) {
    return 'bg-cerise-400'
  }
  return 'bg-gray-200'
}

const getBackdropBlur = (shouldBlur: boolean) => {
  if (shouldBlur) {
    return styles.backdropBlur
  }
  return `${styles.backdropBlur} ${styles.backdropBlurHidden}`
}

const getStatus = (isComplete: boolean, isBlocked: boolean) => {
  if (isComplete) {
    return Status.done
  }
  if (isBlocked) {
    return Status.blocked
  }
  return Status.todo
}

type Props = {
  isModalEnabled?: boolean,
  name?: string, // only needed because of stupid SVG masking issue with unique ID on tag
  forceOpen?: boolean, // if we need to create a new blog, we force the checklist open
}


const OnboardingChecklist = ({ isModalEnabled = false, name = 'desktop', forceOpen = false }: Props) => {
  const { user, isUserLoading } = useUser()
  const { blogs } = useBlogs()
  const { pages, isPagesLoading } = usePagesForDomain()
  const hasPageWithConfirmedPageView = !!(pages?.find((page: Page) => page.isPageViewConfirmed)
  )
  const getIsChecklistNeeded = () => {
    if (forceOpen) {
      return true
    }
    if (isPagesLoading || isUserLoading) {
      return false
    }
    if (blogs?.length && pages?.length) {
      return false
    }
    if (hasPageWithConfirmedPageView) {
      return false
    }
    return true
  }
  const isChecklistNeeded = getIsChecklistNeeded()

  // console.log('IS CHECKLIST NEEDED', isChecklistNeeded, !!blogs?.length, !!pages?.length, !!hasPageWithConfirmedPageView, isPagesLoading)
  // console.log('Onboarding checklist', { user, blogs, pages })
  useEffect(() => {
    const justSignedUpPlan = localStorage.getItem('justSignedUpPlan')
    if (justSignedUpPlan) {
      window.location.replace('/checkout')
    }
  }, [])
  useEffect(() => {
    if (blogs && !isEmpty(blogs)) { // once weve created blog chooser: && blogs?.length === 1
      const blog = first(blogs.sort((a, b) => {
        const aUpdated = a.updatedAt || 0
        const bUpdated = b.updatedAt || 0
        if (aUpdated < bUpdated) {
          return 1
        }
        if (aUpdated > bUpdated) {
          return -1
        }
        return 0
      }))
      const currentPath = window.location.pathname
      const specificBlogPath = `/app/host/${blog?.slug}`
      // console.log({ currentPath, specificBlogPath })
      if (['/app/host/undefined', '/app/host', '/app'].includes(currentPath)) {
        window.location.replace(specificBlogPath)
      }
    }
  }, [blogs])
  // if we check for isPagesLoading here it will never work for new users w/o blogs set up
  if (!user || isUserLoading || !isChecklistNeeded) {
    return <></>
  }
  const listWithStatuses = onboardingEvents.map((event) => {
    const isEventComplete = event.isComplete({ user, blogs, pages })
    const isEventBlocked = event.isBlocked({ user, blogs, pages })
    return {
      ...event,
      status: getStatus(isEventComplete, isEventBlocked)
    }
  })
  // console.log('USER', { user })
  // console.log('OBC', { blogs })
  const needsBlog = isEmpty(blogs)
  const isModalActive = isModalEnabled && needsBlog
  // console.log({ needsBlog })
  // needsBlog = false

  return <>
    <div className={isModalActive
      ? styles.onboardingChecklistHighlight
      : styles.onboardingChecklist}>
      <div className={isModalActive
        ? styles.blotterLogoHighlight
        : styles.blotterLogoHidden
      }>
        <BlotterLogo name={`onboardingchecklist_${name}`} />
      </div>
      <div className="flow-root">
        <div className={styles.heading}>
          <h2 className={styles.title}>Welcome To Blotter</h2>
          <h3 className={styles.subtitle}>Ready to give your blog superpowers?</h3>
        </div>
        <ul role="list" className="mb-0">
          {listWithStatuses.map((event, eventIdx) => {
            const isComplete = event.status === Status.done
            const isActive = event.status === Status.todo
            const isBlocked = event.status === Status.blocked
            const activeBody = isActive && event.renderActiveBody()
            return <li key={event.id}>
              <div className="relative pb-8">
                {eventIdx !== onboardingEvents.length - 1 ? (
                  <span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
                ) : null}
                <div className="relative flex">
                  <div>
                    <span
                      className={classNames(
                        getIconBackground(event.status),
                        'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white mr-2'
                      )}
                    >
                      <event.icon className="h-5 w-5 text-white" aria-hidden="true" />
                    </span>
                  </div>
                  <div className="min-w-0 flex-1 pt-1.5 flex justify-between">
                    <div>
                      <p className={classNames(styles.onboardingItemLabel, {
                        [styles.onboardingItemLabelBlocked]: isBlocked,
                        [styles.onboardingItemLabelDone]: isComplete,
                      })}>
                        { isComplete ? event.contentDone : event.contentTodo }{' '}
                        <a href={event.href} className="font-medium text-gray-900 underline">
                          {event.target}
                        </a>
                      </p>
                    </div>
                    <div className="text-right text-sm whitespace-nowrap text-gray-500">
                      {/* <time dateTime={event.datetime}>{event.date}</time> */}
                      {isActive || isComplete && <>
                        {isComplete
                          ? event.secondaryIconDone
                          : event.secondaryIconTodo
                        }
                      </>}
                    </div>
                  </div>
                </div>
                {
                  isActive && activeBody && <div className={styles.activeBody}>
                    {activeBody}
                  </div>
                }
              </div>
            </li>
          })}
        </ul>
      </div>
    </div>
    <div className={getBackdropBlur(isModalActive)} />
  </>
}

export default OnboardingChecklist
