import useSWR from 'swr'
import { DomainOption } from './types'

type DomainOptionDto = {
  domainOptions: DomainOption[],
}

export const useDomainOptions = () => {
  // console.log('useDomainOptinos')
  const { data: domain, error } = useSWR<DomainOptionDto>('/domainoptions')

  return {
    domainOptions: domain?.domainOptions || [],
    isDomainOptionsLoading: !error && !domain,
    isDomainOptionsError: error,
  }
}
