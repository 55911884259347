import LoadingContent from 'components/LoadingContent'
import RadioGroup, { Option } from 'components/RadioGroup/RadioGroup'
import Button from 'components/Button'
import { useDomainOptions } from 'data/domainOptions/client'
import { optionFromDomainOption } from 'data/domainOptions/mapping'
import { updateBlogs } from 'data/blogs/client'
import { FormEvent, useEffect, useRef, useState } from 'react'
import styles from './DomainSelector.module.css'
import classNames from 'classnames'
import { BlogPartial } from 'data/blogs/types'
import { isUrl } from 'utilities/url'
import { InformationCircleIcon } from '@heroicons/react/outline'
// import { useRouter } from 'next/router'
// import { first } from 'lodash'

type OptionWithIsOther = Option & {
  isOtherOption?: boolean,
}

const DomainSelector = () => {
  const { domainOptions, isDomainOptionsLoading } = useDomainOptions()
  const [otherValue, setOtherValue] = useState<string>('')
  const [isSubmitted, setIsSubmitted] = useState(false)
  // const [isCreating, setIsCreating] = useState(false)
  const otherRef = useRef<HTMLInputElement>(null)

  const options =
    domainOptions
      ? domainOptions.map(optionFromDomainOption)
      : []
  const hasDomainOptions = options.length > 0
  const optionsWithOther = [
    ...options,
    otherOption,
  ]
  const [selectedOption, setSelectedOption] = useState<OptionWithIsOther>()
  const [blogName, setBlogName] = useState('')
  // const router = useRouter()
  useEffect(() => {
    if (!selectedOption) {
      setSelectedOption(options[0])
    }
  }, [options])
  useEffect(() => {
    if (selectedOption?.value === otherOption.value) {
      otherRef?.current?.focus()
    }
  }, [selectedOption])
  const setToOther = () => {
    setSelectedOption(otherOption)
  }
  const getSelectedValue = () => {
    if (selectedOption?.value === 'other') {
      return otherValue
    }
    return selectedOption?.value as string
  }
  useEffect(() => {
    // console.log({ otherValue })
    if (otherValue?.includes('https://') || otherValue?.includes('http://')) {
      const otherValueClean = otherValue.replace('https://', '').replace('http://', '')
      setOtherValue(otherValueClean)
    }
  }, [otherValue])
  const handleFormSubmit = async (e: FormEvent<HTMLFormElement> | MouseEvent) => {
    e.preventDefault()
    if (!isSubmitted) {
      setIsSubmitted(true)
      setTimeout(() => { setIsSubmitted(false) }, 30000)
      const createBlogPayload: BlogPartial = {
        domain: getSelectedValue(),
        blogName,
      }
      // setIsCreating(true)
      updateBlogs(createBlogPayload)
      // const result = await post('/blogs', createBlogPayload)
      // refreshBlogs()
    }
  }
  // const { blogs } = useBlogs()
  // useEffect(() => {
  //   if (blogs) { // isCreating &&
  //     const blogsSorted = blogs.sort((a,b) =>
  //       a?.createdAt < b?.createdAt
  //         ? 1
  //         : a?.createdAt === b?.createdAt
  //           ? 0
  //           : -1
  //       )
  //     console.log('blogs sorted', blogsSorted)
  //     const
  //     newBlog = first(blogsSorted)
  //     if (newBlog?.slug) {
  //       router.push(`/app/host/${newBlog.slug}`)
  //     }
  //   }
  // }, [isCreating, blogs])

  if (isDomainOptionsLoading) {
    return <LoadingContent label='Domain Options Loading' />
  }
  return <form onSubmit={handleFormSubmit}>
    <div className="flex justify-between mb-1">
      <label htmlFor="blog_domain" className="text-sm font-medium text-gray-700 flex gap-1">
        Blog Root URL
        <div className={styles.blogDomainInfoTooltipContainer}>
          <InformationCircleIcon width={18} />
          <div role="tooltip" className={styles.blogDomainInfoTooltip}>
              The root URL of your blog<br />Examples: <ul><li><em>example.com/blog</em></li><li><em>exampleblog.com</em></li><li><em>blog.example.com</em></li></ul>
              <div className='tooltip-arrow'></div>
          </div>
        </div>
      </label>
      <span className="text-sm text-gray-500" id="blog_domain-required">
        Required
      </span>
    </div>
    { hasDomainOptions && <RadioGroup
      titleForScreenReader='Blog Root URL'
      options={optionsWithOther}
      value={selectedOption}
      onChange={(selectedItem) => {
        const selection = optionsWithOther.find(option => selectedItem.value === option.value)
        setSelectedOption(selection)
      }}
      suppressClosingStyling
    /> }
    <div className={classNames('p-4 pl-10 border',
      hasDomainOptions ? 'pt-0 rounded-b-md border-t-0' : 'rounded-md',
      selectedOption?.value === otherOption.value
        ? 'bg-indigo-50 border-indigo-200 z-10'
        : 'border-gray-200'
    )}>
      <div className="mt-0 flex rounded-md shadow-sm">
        <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
          https://
        </span>
        <input
          type="text"
          name="blog-domain"
          id="blog-domain"
          className="flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300"
          placeholder="example.com"
          onFocus={setToOther}
          onChange={(ev) => { setOtherValue(ev.target.value) }}
          value={otherValue}
          ref={otherRef}
          // tabIndex={0}
        />
      </div>
    </div>
    <div>
      <div className="flex justify-between mt-4">
        <label htmlFor="blog_title" className="block text-sm font-medium text-gray-700">
          Blog Title
        </label>
        <span className="text-sm text-gray-500" id="blog_title-optional">
          Optional
        </span>
      </div>
      <div className="mt-1">
        <input
          type="text"
          name="blog_title"
          id="blog_title"
          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
          placeholder="My Amazing Blog"
          aria-describedby="blog_title-optional"
          value={blogName}
          onChange={(ev) => { setBlogName(ev?.target?.value) }}
        />
      </div>
    </div>
    <div className='mt-4 w-full'>
      <Button
        variant='medium'
        isDisabled={!isUrl(getSelectedValue())}
        type='submit'
      >Save Blog Details &raquo;</Button>
    </div>
  </form>
}

// this is annoying but Headless UI RadioGroup doesn't like it when the
// otherOption object is recreated between renders so we create it outside the component
const otherOption: OptionWithIsOther = {
  value: 'other',
  title: <label htmlFor="blog-domain" className="block text-sm font-medium text-gray-700">Or, enter the root url of your blog</label>,
  body: '',
  isOtherOption: true,
}

export default DomainSelector
