// import getConfig from 'next/config'
import styles from './PageApp.module.css'
import { useState } from 'react'
import LoginView from 'views/LoginView'
import {
  // BellIcon,
  MenuAlt3Icon,
} from '@heroicons/react/outline'
import Sidebar from 'components/Sidebar'
import AccountMenu from 'components/AccountMenu'
import { useSession } from 'next-auth/react'
import LoadingContent from 'components/LoadingContent'
// import Script from 'next/script'
import FontPreload from 'components/FontPreload'

type Props = {
  children: React.ReactNode,
  top?: React.ReactNode,
  heading?: React.ReactNode,
  isLoading?: boolean,
  loadingLabel?: string,
}

const PageApp = ({ children, top, heading, isLoading = false, loadingLabel = 'Loading...' }: Props) => {
  const { data: session } = useSession()
  const [sidebarOpen, setSidebarOpen] = useState(false)
  // const { publicRuntimeConfig } = getConfig()
  return <div className={styles.pageApp}>
    <FontPreload />
    {/* <Script src={`${publicRuntimeConfig.blotterDomain}/static/js/mouseflow.js`} strategy='afterInteractive' /> */}
    {
      session === null
      ? <LoginView />
      : <>
        { top }
        <Sidebar
          isSidebarOpen={sidebarOpen}
          setIsSidebarOpen={setSidebarOpen}
        />
        <div className={styles.content}>
          <div className={styles.heading}>
            <div className="flex-1 px-4 flex justify-end sm:px-6 lg:max-w-7xl lg:mx-auto lg:px-16 py-2">
              { heading }
              {/* <SearchBar /> */}
              <div className="ml-4 hidden sm:flex items-center justify-end md:ml-6 w-[74px]">
                {/* <button
                  type="button"
                  className="bg-white p-1 rounded-full text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
                >
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button> */}
                <AccountMenu />
              </div>
            </div>
            <button
              type="button"
              className={styles.sidebarButtonMobile}
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <MenuAlt3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <main className={styles.body}>
            { isLoading
              ? <LoadingContent label={loadingLabel} />
              : children
            }
          </main>
        </div>
      </>
    }
  </div>
}

export default PageApp
