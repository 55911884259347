import Head from 'next/head'

type Props = {
  fonts?: string[],
}

const FontPreload = ({ fonts = ['montserrat', 'josefinsans', 'lora'] }: Props) =>
  <Head>
    {/* Font preloading moved to _document.js per Next docs */}
    {/* Apparently this approach doesn't work well */}
    { fonts.includes('josefinsans') && <>
      <link rel='preload' as='font' href='/static/fonts/josefinsans-bold.woff2' crossOrigin='' />
    </> }
  </Head>

export default FontPreload
