import { Option } from 'components/RadioGroup/RadioGroup'
import { DomainOption } from 'data/domainOptions/types'

export const optionFromDomainOption = (domainOption: DomainOption): Option => ({
  title: domainOption.url,
  body: domainOption.note,
  value: domainOption.value || domainOption.url,
})

// export const domainOptionFromOption = (option: Option): DomainOption => ({
//   url: option.title,
//   note: option.body,
//   value: option.value,
// })
